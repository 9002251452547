import { VideoProp } from './themes/Video/types'

export enum Theme {
  about = 'about',
  beaverton = 'beaverton',
  careers = 'careers',
  carriers = 'carriers',
  contact = 'contact',
  eugene = 'eugene',
  franchising = 'franchising',
  grantsPass = 'grantsPass',
  lakeOswego = 'lakeOswego',
  lasVegas = 'LasVegas',
  location = 'location',
  locations = 'locations',
  medford = 'medford',
  news = 'news',
  press = 'press',
  productdetail = 'productdetail',
  productlist = 'productlist',
  auto = 'auto',
  business = 'business',
  disability = 'disability',
  group = 'group',
  groupQuote = 'groupQuote',
  health = 'health',
  home = 'home',
  retirement = 'retirement',
  life = 'life',
  medicare = 'medicare',
  reno = 'reno',
  story = 'story',
  support = 'support',
  team = 'team',
  video = 'video',
  noimage = 'noimage',
}

export interface Props {
  cta?: boolean,
  ctaIsExternal?: boolean,
  ctaText?: string,
  ctaTitle?: string,
  ctaUrl?: string,
  imgMobile?: string,
  right?: boolean,
  theme: Theme,
  video?: VideoProp,
  videoPoster?: string,
  isLargeHeight?: boolean,
  hasOpacity?: boolean,
  hasLargeTitle?: boolean,
  largeTitle?: string,
}
