import React from 'react'
import loadable from '@loadable/component'

import Link, { Theme as LinkTheme } from 'components/base/Link'

import {
  Color,
  Props,
  Size,
} from './types'

import {
  AskQuestionWrap,
  AskQuestionContent,
  QuestionText,
  QuestionTitle,
} from './styles'

const ConciergeIcon = loadable(() => import('../ConciergeIcon').catch(() => () => null))

const defaultButton = (
  <Link theme={LinkTheme.button} to='/support/ask-a-question/'>
    Ask a Question
  </Link>
)

const AskQuestion: React.FC<Props> = ({
  buttons = defaultButton,
  children,
  color = Color.regular,
  size = Size.regular,
  speech = 'Hi!',
  title,
}): React.ReactElement => (
  <AskQuestionWrap>
    <AskQuestionContent>
      {(title && <QuestionTitle color={color} size={size}>{title}</QuestionTitle>) || undefined}
      <ConciergeIcon title={speech} />
      <QuestionText size={size}>{children}</QuestionText>
      {buttons}
    </AskQuestionContent>
  </AskQuestionWrap>
)

export * from './types'

export default AskQuestion
