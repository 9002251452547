import styled from 'styled-components'

import MEDIA from 'constants/media'


import StopWatch from 'assets/images/icons/Stopwatch.svg'
import Magnifying from 'assets/images/icons/Magnifying.svg'
import Light from 'assets/images/icons/Light.svg'

import QuoteApple from 'assets/images/icons/quote/Apple.svg'
import QuoteCalendar from 'assets/images/icons/quote/Calendar.svg'
import QuoteFolder from 'assets/images/icons/quote/Folder.svg'
import QuoteHouse from 'assets/images/icons/quote/House.svg'
import QuoteMan from 'assets/images/icons/quote/Man.svg'
import QuoteRing from 'assets/images/icons/quote/Ring.svg'
import QuoteSpeedometer from 'assets/images/icons/quote/Speedometer.svg'
import QuoteWheel from 'assets/images/icons/quote/Wheel.svg'
import QuoteAccident from 'assets/images/icons/quote/Accident.svg'
import QuoteLoss from 'assets/images/icons/quote/Loss.svg'
import QuoteViolation from 'assets/images/icons/quote/Violation.svg'
import QuoteHouseDrawing from 'assets/images/icons/quote/HouseDrawing.svg'
import QuoteCord from 'assets/images/icons/quote/Cord.svg'
import QuoteFamily from 'assets/images/icons/quote/Family.svg'
import QuoteFaucet from 'assets/images/icons/quote/Faucet.svg'
import QuoteRoof from 'assets/images/icons/quote/Roof.svg'
import QuoteThermostat from 'assets/images/icons/quote/Thermostat.svg'
import QuoteAlert from 'assets/images/icons/quote/Alert.svg'
import QuoteGPS from 'assets/images/icons/quote/GPS.svg'
import QuoteHouseCheck from 'assets/images/icons/quote/HouseCheck.svg'
import QuoteMortgage from 'assets/images/icons/quote/Mortgage.svg'
import QuoteCreditCard from 'assets/images/icons/quote/CreditCard.svg'
import QuoteDollarSign from 'assets/images/icons/quote/DollarSign.svg'
import QuoteQuestionBubble from 'assets/images/icons/quote/QuestionBubble.svg'
import QuotePen from 'assets/images/icons/quote/Pen.svg'
import CoverageSilver from 'assets/images/icons/coverage/Silver.svg'
import CoverageGold from 'assets/images/icons/coverage/Gold.svg'
import CoverageDiamond from 'assets/images/icons/coverage/Diamond.svg'
import CoveragePlatinum from 'assets/images/icons/coverage/Platinum.svg'
import CoverageLevels from 'assets/images/icons/coverage/Levels.svg'

import ConnectCase from 'assets/images/icons/connect/Case.svg'
import ConnectComputer from 'assets/images/icons/connect/Computer.svg'
import ConnectMic from 'assets/images/icons/connect/Mic.svg'

import Accident from 'assets/images/icons/Accident.svg'
import TowTruck from 'assets/images/icons/TowTruck.svg'

import ErrorTriangle from 'assets/images/icons/ErrorTriangle.svg'

export const ErrorIcon = styled.span`
  display: inline-block;
  height: 25px;
  width: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${ErrorTriangle});
`

export const AccidentIcon = styled.span`
  display: inline-block;
  height: 144px;
  width: 232px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${Accident});
`

export const TowTruckIcon = styled.span`
  display: inline-block;
  height: 144px;
  width: 232px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(${TowTruck});
`

export const ConnectIcon = styled.span`
  display: inline-block;
  height: 90px;
  width: 90px;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  ${MEDIA.medium`
    height: 130px;
    width: 130px;
  `}
`

export const ConnectIconCase = styled(ConnectIcon)`
  background-image: url(${ConnectCase});
`

export const ConnectIconComputer = styled(ConnectIcon)`
  background-image: url(${ConnectComputer});
`

export const ConnectIconMic = styled(ConnectIcon)`
  background-image: url(${ConnectMic});
  background-size: auto 80px;
  ${MEDIA.medium`
    background-size: auto 100px;
  `}
`

export const ConnectIconPen = styled(ConnectIcon)`
  background-image: url(${QuotePen});
  background-size: auto 60px;
  ${MEDIA.medium`
    background-size: auto 90px;
  `}
`

export const SiteIcon = styled.span`
  display: inline-block;
  height: 140px;
  width: 140px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 140px;
`

export const SiteIconStopWatch = styled(SiteIcon)`
  background-image: url(${StopWatch});
`

export const SiteIconMagnifying = styled(SiteIcon)`
  background-image: url(${Magnifying});
`

export const SiteLight = styled(SiteIcon)`
  background-image: url(${Light});
`

export const QuoteIcon = styled.span`
  display: inline-block;
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 60px 60px;
  margin-top: 0;
  ${MEDIA.medium`
    background-position: center bottom;
  `}
`

export const QuoteIconPen = styled(QuoteIcon)`
  background-image: url(${QuotePen});
`

export const QuoteIconQuestionBubble = styled(QuoteIcon)`
  background-image: url(${QuoteQuestionBubble});
`

export const QuoteIconDollarSign = styled(QuoteIcon)`
  background-image: url(${QuoteDollarSign});
`

export const QuoteIconCreditCard = styled(QuoteIcon)`
  background-image: url(${QuoteCreditCard});
`

export const QuoteIconApple = styled(QuoteIcon)`
  background-image: url(${QuoteApple});
`

export const QuoteIconCalendar = styled(QuoteIcon)`
  background-image: url(${QuoteCalendar});
`

export const QuoteIconFolder = styled(QuoteIcon)`
  background-image: url(${QuoteFolder});
`

export const QuoteIconHouse = styled(QuoteIcon)`
  background-image: url(${QuoteHouse});
`

export const QuoteIconMan = styled(QuoteIcon)`
  background-image: url(${QuoteMan});
`

export const QuoteIconRing = styled(QuoteIcon)`
  background-image: url(${QuoteRing});
`

export const QuoteIconSpeedometer = styled(QuoteIcon)`
  background-image: url(${QuoteSpeedometer});
`

export const QuoteIconWheel = styled(QuoteIcon)`
  background-image: url(${QuoteWheel});
`

export const QuoteIconAccident = styled(QuoteIcon)`
  background-image: url(${QuoteAccident});
`

export const QuoteIconLoss = styled(QuoteIcon)`
  background-image: url(${QuoteLoss});
`

export const QuoteIconViolation = styled(QuoteIcon)`
  background-image: url(${QuoteViolation});
`

export const QuoteIconCord = styled(QuoteIcon)`
  background-image: url(${QuoteCord});
`

export const QuoteIconFamily = styled(QuoteIcon)`
  background-image: url(${QuoteFamily});
`

export const QuoteIconFaucet = styled(QuoteIcon)`
  background-image: url(${QuoteFaucet});
`

export const QuoteIconHouseDrawing = styled(QuoteIcon)`
  background-image: url(${QuoteHouseDrawing});
`

export const QuoteIconRoof = styled(QuoteIcon)`
  background-image: url(${QuoteRoof});
`

export const QuoteIconThermostat = styled(QuoteIcon)`
  background-image: url(${QuoteThermostat});
`

export const QuoteIconAlert = styled(QuoteIcon)`
  background-image: url(${QuoteAlert});
`

export const QuoteIconGPS = styled(QuoteIcon)`
  background-image: url(${QuoteGPS});
`

export const QuoteIconHouseCheck = styled(QuoteIcon)`
  background-image: url(${QuoteHouseCheck});
`

export const QuoteIconMortgage = styled(QuoteIcon)`
  background-image: url(${QuoteMortgage});
`

export const CoverageIcon = styled.span`
  display: inline-block;
  height: 65px;
  width: 65px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  margin-top: 10px;
  ${MEDIA.medium`
    height: 80px;
    width: 80px;
  `}
`

export const CoverageIconSilver = styled(CoverageIcon)`
  background-image: url(${CoverageSilver});
`

export const CoverageIconGold = styled(CoverageIcon)`
  background-image: url(${CoverageGold});
`

export const CoverageIconDiamond = styled(CoverageIcon)`
  background-image: url(${CoverageDiamond});
`

export const CoverageIconPlatinum = styled(CoverageIcon)`
  background-image: url(${CoveragePlatinum});
`

export const CoverageIconLevels = styled(CoverageIcon)`
  background-image: url(${CoverageLevels});
  background-size: 60px auto;
`
