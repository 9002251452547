
import styled from 'styled-components'

import COLORS from 'constants/colors'
import MEDIA from 'constants/media'
import FONTS from 'constants/fonts'


export const PageHeroFooter = styled.div`
  padding: 0 20px;
  ${MEDIA.medium`
    display: flex;
    justify-content: center;
  `}
`

export const PageHeroFooterContainer = styled.div`
  background: rgba(255, 255, 255, 1);
  border: 2px solid ${COLORS.BORDERS};
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  margin: 0px auto 0;
  padding: 20px;
  max-width: 640px;
  width: 100%;
  .icon {
    margin-top: -100px;
    margin-left: auto;
    margin-right: auto;
  }
  h3 {
    font-size: 36px;
    margin: 0 0 15px 0;
  }
  span {
    font-weight: 300;
    font-size: ${FONTS.PARAGRAPHLARGE};
    display: block;
    margin: 10px 0 20px;
  }
  ${MEDIA.tiny`
    margin: -60px auto 0;
  `}
  ${MEDIA.medium`
    margin: 20px 20px 40px;
    padding: 30px;
    max-width: 600px;
    background: rgba(255, 255, 255, 0.8);
  `}
`
